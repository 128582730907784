import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import meReducer from '../features/me/me';
import plansReducer from '../features/plans/plans';
import routersReducer from '../features/routers/routers';
import termsReducer from '../features/terms/terms';
import campaignsReducer from '../features/campaigns/campaigns';
import referralsReducer from '../features/referrals/refarrals';
import searchReducer from '../features/search/search';
import activeReservationsReducer from '../features/active_reservations/active_reservations';

export const store = configureStore({
  reducer: {
    me: meReducer,
    plans: plansReducer,
    routers: routersReducer,
    terms: termsReducer,
    campaigns: campaignsReducer,
    referrals: referralsReducer,
    active_reservations: activeReservationsReducer,
    search: searchReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
