import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, IconButton, Snackbar } from '@mui/material';

export interface CopyToClipboardButtonProps {
  textToCopy: string
}

const CopyToClipboardButton = ({ textToCopy } : CopyToClipboardButtonProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <>
      <IconButton onClick={handleClick}><ContentCopyIcon /></IconButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboardButton;
