import { GroupAdd } from '@mui/icons-material';
import {
  TextField, Grid, Button, Paper, Modal, Typography, FormControlLabel, Checkbox, Avatar, Chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import {
  createAndRedeem, fetchReferralReport, redeemReferral, Referral,
} from '../features/referrals/refarrals';
import { searchSubscriptions, Subscription, SubscriptionHubspot } from '../features/search/search';
import { getOurDealStage } from '../utils';

interface FormValues {
  email: string
  subId: string
  firstName: string
  lastName: string
}

const modalStyle = {
  position: 'absolute' as const,
  bgcolor: 'grey',
  top: '20%',
  left: '30%',
  width: 500,
  height: 300,
  color: 'white',
  borderRadius: '10px',
  p: 4,
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const PostSignUpReferral = () => {
  const p = useParams();
  const referralCode = p.referralCode as string;

  const [showRedeem, setShowRedeem] = useState(false);
  const [redeemInChargeBee, setRedeemInChargeBee] = useState('no');

  const [searchParams, setSearchParams] = useSearchParams();
  const isSales = (/true/i).test(searchParams.get('sales') || '');

  const dispatch = useAppDispatch();
  const searchState = useAppSelector((state: RootState) => state.search);

  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

  const submitHandler = async (values: FormValues) => {
    dispatch(searchSubscriptions({
      ...values, code: referralCode,
    }));
  };

  const referralState = useAppSelector((state: RootState) => state.referrals);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [serviceID, setServiceID] = useState('');
  const [referralType, setReferralType] = useState(0);
  const [cbID, setCBID] = useState('');

  useEffect(() => {
    dispatch(fetchReferralReport({
      sales: isSales,
      showPending: false,
      code: referralCode,
    }));
  }, []);

  useEffect(() => {
    const filteredByCode = referralState.value.filter((referral) => referral.referral_code === referralCode && referral.referral_code === referral.sk);
    if (filteredByCode.length > 0) {
      setEmail(filteredByCode[0].referrer_email);
      setFirstName(filteredByCode[0].first_name);
      setLastName(filteredByCode[0].last_name);
      setCBID(filteredByCode[0].cb_id);
      setServiceID(filteredByCode[0].service_id);
      setReferralType(filteredByCode[0].referral_object_type);
    }
  }, [referralState.value]);

  const handleRedeemClose = () => setShowRedeem(false);
  const handleRedeem = (sub:SubscriptionHubspot) => async () => {
    await dispatch(createAndRedeem({
      ...sub.subscription, code: referralCode, sid: sub.subscription.custom_field.cf_customer_reference_number, hubspot_deal_id: sub.hubspot.id,
    }));
    setRedeemInChargeBee('no');
    setShowRedeem(false);
    window.location.href = `/dashboard/${isSales ? 'commission_sales' : 'referrals'}/referral_report/${referralCode}`;
  };

  const stringAvatar = (name: string) => ({
    sx: {
      bgcolor: stringToColor(name),
      width: 56,
      height: 56,
    },
    src: name === 'Ja Gemmell' ? 'https://avatars.githubusercontent.com/u/3252430?v=4' : undefined,
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  });

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >

      <Grid container rowSpacing={6} spacing={0}>
        <Grid item xs={2} />
        <Grid item xs={1}>
          <Box sx={{ marginTop: 12 }}>
            <Avatar
              {...stringAvatar(`${firstName} ${lastName}`)}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ marginTop: 10 }}>
            <h2>
              {firstName}
              {' '}
              {lastName}
            </h2>
            <p>{email}</p>
            { isSales ? null : <p>{serviceID}</p>}
            <p>
              Referral code:
              {' '}
              <Chip label={referralCode} />
            </p>
          </Box>
        </Grid>

        <Grid style={{ marginTop: '30px', alignContent: 'center' }} item xs={1}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            href={`/dashboard/${isSales ? 'commission_sales' : 'referrals'}/referral_report/${referralCode}`}
          >
            Back to referrals
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: '10px', alignContent: 'center' }} item xs={1}>
        <Paper elevation={3}>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 2, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-controlled"
              label="Chargebee Subscription Id"
              placeholder="AzZZ7BUCjcJ5u1BFn"
              {...register('subId')}
            />

            <TextField
              id="outlined-controlled"
              label="Email"
              type="email"
              placeholder="jody.doran@dgtek.net"
              helperText={errors.email ? 'email is invalid' : null}
              error={!!errors.email}
              {...register('email', {
                validate: (e: string) => {
                  if (e) {
                    return !!e.match(/^[^@]+@[^@]+\.[^@]+$/);
                  }
                  return true;
                },
              })}
            />

            <TextField
              id="outlined-controlled"
              placeholder="Jody Doran"
              label="First name"
              {...register('firstName')}
            />

            <TextField
              id="outlined-controlled"
              placeholder="Jody Doran"
              label="Last name"
              {...register('lastName')}
            />

            <Button variant="outlined" type="submit" onClick={handleSubmit(submitHandler)} disabled={searchState.status === 'loading'}>
              {
              searchState.status === 'loading' ? 'Loading...' : 'Search'
}
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid style={{ marginTop: '30px', alignContent: 'center' }} item xs={1}>
        <DataGrid
          rows={searchState?.value}
          getRowId={(row) => row.subscription.id}
          loading={searchState.status === 'loading'}
          columns={[
            {
              field: 'id', headerName: 'Chargebee SubId', width: 250, renderCell: (params) => (<a target="_blank" href={`https://pineapple.chargebee.com/d/subscriptions/${params.row?.subscription?.id}`} rel="noreferrer">{params.row?.subscription?.id}</a>),
            },
            {
              field: 'customer_id', headerName: 'Customer Id', width: 250, valueGetter: (params) => params.row?.subscription?.customer_id,
            },
            {
              field: 'custom_field', headerName: 'SID', width: 250, valueGetter: (params) => params.row?.subscription?.custom_field?.cf_customer_reference_number,
            },
            {
              field: 'hubspot_deal_id',
              headerName: 'Hubspot Deal ID',
              width: 250,
              renderCell: (params) => (<a target="_blank" href={`https://app.hubspot.com/contacts/8498425/record/0-3/${params.row?.hubspot?.id}`} rel="noreferrer">{params.row?.hubspot?.id}</a>),
            },
            {
              field: 'hubspot_deal_stage', headerName: 'Hubspot Deal Stage', width: 250, valueGetter: (params) => getOurDealStage(params.row?.hubspot?.properties?.dealstage),
            },

            {
              field: 'cb_id',
              headerName: 'Redeem',
              editable: false,
              renderCell: (cellValues) => {
                if (cellValues.hasFocus === true) {
                  return (
                    <>
                      <Modal
                        sx={modalStyle}
                        open={showRedeem}
                        onClose={handleRedeemClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure that you want to manually redeem the referral ?
                          </Typography>
                          <br />

                          <Typography>
                            SID:&nbsp;
                            <b>
                              {cellValues.row?.subscription.custom_field.cf_customer_reference_number}
                            </b>
                          </Typography>

                          <Typography>
                            Record:&nbsp;
                            <b>
                              {`${cellValues.row?.subscription.customer_id}#${cellValues.row.subscription.id}`}
                            </b>
                          </Typography>

                          <Typography>
                            Email:&nbsp;
                            <b>
                              {cellValues.row?.subscription.customer_id}
                            </b>
                          </Typography>

                          <Typography>
                            Hubspot Deal Id:&nbsp;
                            <b>
                              {cellValues.row?.hubspot.id}
                            </b>
                          </Typography>

                          <Typography>
                            Hubspot Deal Name:&nbsp;
                            <b>
                              {cellValues.row?.hubspot.properties.dealname}
                            </b>
                          </Typography>

                          <Typography>
                            Hubspot Deal Stage:&nbsp;
                            <b>
                              {getOurDealStage(cellValues.row?.hubspot.properties.dealstage)}
                            </b>
                          </Typography>

                          <div style={{ marginTop: '10px' }}>
                            <span style={{ display: 'block', float: 'left' }}>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleRedeem(cellValues.row)}
                              >
                                Proceed
                              </Button>
                            </span>
                            <span style={{ display: 'block', float: 'right' }}>
                              <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={handleRedeemClose}
                              >
                                Cancel
                              </Button>
                            </span>
                          </div>
                        </Box>
                      </Modal>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => setShowRedeem(true)}
                      >
                        Redeem
                      </Button>
                    </>
                  );
                } return <GroupAdd />;
              },
              width: 80,
            },

          ]}
        />
      </Grid>
    </Grid>
  );
};
