import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Campaign, rfc3339 } from '../../features/campaigns/campaigns';
import { Plan } from '../../features/plans/plans';
import { BuildingManager } from './BuildingManager';
import CouponField from './CouponField';

const collator = new Intl.Collator(undefined, { numeric: true });

const validCampaign = (campaign: Campaign, pricingStrategy: PricingStrategy): boolean => {
  const {
    name, startDate, endDate, monthsFree, couponCode, plans, buildings,
  } = campaign;

  // These fields must have values
  if (![name, startDate, endDate].every((val) => val)) return false;

  // There must be at least one building
  if (buildings.length === 0) return false;

  // We need plans for CustomPlanSet campaigns
  if (pricingStrategy === 'CustomPlanSet') return plans.length > 0;

  // Assume it's a monthsFree campaign from this point. Expect a value.
  if (monthsFree === undefined) return false;

  // Can't have negative months
  if (monthsFree <= 0) return false;

  // We need coupon codes for monthsFree
  if (!couponCode) return false;

  return true;
};

type PricingStrategy = 'MonthsFree' | 'CustomPlanSet';

export const NewCampaignModal: React.FC<{
  initialCampaign: Campaign;
  avaliablePlans: Plan[];
  open: boolean;
  title: string;
  onModalClose: (_: Campaign | undefined) => void;
}> = ({
  initialCampaign,
  avaliablePlans,
  open,
  title,
  onModalClose,
}) => {
  const [campaign, setCampaign] = useState(initialCampaign);
  const [pricingStrategy, setPricingStrategy] = useState<PricingStrategy>(
    (campaign.monthsFree === undefined) ? 'CustomPlanSet' : 'MonthsFree',
  );

  const plansMap: { [id: string]: Plan } = avaliablePlans.reduce((
    acc: {[id: string]: Plan},
    plan: Plan,
  ) => {
    acc[plan.id] = plan;
    return acc;
  }, {});

  const handlePlansChange = (event: SelectChangeEvent<string[]>) => {
    const planIds = event.target.value as string[];
    const plans = planIds.map((planId) => plansMap[planId]);
    setCampaign({ ...campaign, plans });
  };

  const handlePricingStrategyChange = (
    _: React.MouseEvent<HTMLElement>,
    newPricingStrategy: PricingStrategy | null,
  ) => newPricingStrategy && setPricingStrategy(newPricingStrategy);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validCampaign(campaign, pricingStrategy)) return;

    switch (pricingStrategy) {
      case 'MonthsFree':
        onModalClose({ ...campaign, plans: [] });
        break;
      case 'CustomPlanSet':
        delete campaign.monthsFree;
        delete campaign.couponCode;
        onModalClose(campaign);
        break;
      default:
    }
  };

  return (
    <Dialog open={open} onClose={() => onModalClose(undefined)} maxWidth="sm">
      <DialogTitle>
        { title }
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                label="Name"
                defaultValue={campaign.name}
                onChange={(e) => setCampaign({ ...campaign, name: e.target.value })}
                required
                fullWidth
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="RSP"
                defaultValue={campaign.rsp}
                onChange={(e) => setCampaign({ ...campaign, rsp: e.target.value })}
                disabled
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <BuildingManager
                campaign={campaign}
                onBuildingsChange={(buildings) => setCampaign({ ...campaign, buildings })}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                label="Start Date"
                maxDate={dayjs(campaign.endDate)}
                defaultValue={dayjs(campaign.startDate)}
                onChange={(e) => e && setCampaign({ ...campaign, startDate: e.format(rfc3339) })}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                label="End Date"
                minDate={dayjs(campaign.startDate)}
                defaultValue={dayjs(campaign.endDate)}
                onChange={(e) => e && setCampaign({ ...campaign, endDate: e.format(rfc3339) })}
              />
            </Grid>
            <Grid xs={12}>
              <ToggleButtonGroup
                exclusive
                onChange={handlePricingStrategyChange}
                value={pricingStrategy}
                fullWidth
              >
                <ToggleButton value="MonthsFree">Months Free</ToggleButton>
                <ToggleButton value="CustomPlanSet">Custom Plan Set</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {{
              MonthsFree: (
                <>
                  <Grid xs={6}>
                    <TextField
                      label="Number of months"
                      defaultValue={campaign.monthsFree}
                      onChange={(e) => setCampaign({
                        ...campaign,
                        monthsFree: parseInt(e.target.value, 10),
                      })}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        min: 0,
                      }}
                      type="number"
                      required={pricingStrategy === 'MonthsFree'}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={6}>
                    <CouponField
                      label="Coupon code"
                      defaultValue={campaign.couponCode}
                      onValidate={(value: string, valid: boolean) => {
                        setCampaign({ ...campaign, couponCode: valid ? value : undefined });
                      }}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      label="Additional Terms and Conditions (if any)"
                      defaultValue={campaign.terms}
                      onChange={(e) => setCampaign({ ...campaign, terms: e.target.value })}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                </>
              ),
              CustomPlanSet: (
                <Grid xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="plans-label">Plans</InputLabel>
                    <Select
                      labelId="plans-label"
                      label="Plans"
                      defaultValue={campaign.plans.map(({ id }) => id)}
                      multiple
                      onChange={handlePlansChange}
                      required={pricingStrategy === 'CustomPlanSet'}
                    >
                      {
                        [...avaliablePlans] // don't sort in place
                          .sort((p1, p2) => collator.compare(p1.id, p2.id))
                          .map(({ id, name }) => (
                            <MenuItem key={id} value={id}>{name}</MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              ),
            }[pricingStrategy]}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!validCampaign(campaign, pricingStrategy)}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
