import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useMemo, useState } from 'react';

dayjs.extend(duration);

interface CountdownProps {
  endTime: Dayjs;
  callBack: CallableFunction;
}

const Countdown: React.FC<CountdownProps> = ({
  endTime, callBack,
}) => {
  const [time, setTime] = useState<string>();

  useMemo(() => {
    const currentTime = dayjs();
    const diffTime = endTime.unix() - currentTime.unix();

    let d = dayjs.duration(diffTime * 1000, 'milliseconds');
    const interval = 1000;
    const twoDP = (n: number) => (n > 9 ? n : `0${n}`);

    setInterval(() => {
      d = dayjs.duration(
        d.asMilliseconds() - interval,
        'milliseconds',
      );
      if (d.seconds() < 0) {
        callBack();
        setTime('expired');
        return;
      }

      const timestamp = `${twoDP(d.minutes())}m ${twoDP(d.seconds())}s`;

      setTime(timestamp);
    }, interval);
  }, [endTime]);

  return <span>{ time }</span>;
};

export default Countdown;
