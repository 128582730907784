import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-au';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { RootState, store } from './app/store';
import Middle from './containers/Middle';
import { fetchMe } from './features/me/me';
import './index.css';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './components/ProtectedRoute';
import Plans from './components/Plans';
import Campaigns from './components/campaigns/Campaigns';
import Routers from './components/Routers';
import Terms from './components/Terms';
import Logout from './components/Logout';
import Referrals from './components/Referrals';
import ReferralReportPage from './components/ReferralReportPage';
import ReferAFriendDemo from './components/ReferAFriendBackendDemo';
import CommissionSales from './components/CommissionSales';
import AddSalesPerson from './components/AddSalesPerson';
import ActiveReservations from './components/ActiveReservations';
import { PostSignUpReferral } from './components/PostSignUpReferral';

const container = document.getElementById('root')!;
const root = createRoot(container);

LicenseInfo.setLicenseKey('126d91309f82b23c1e0ba1c960f4b653Tz02MDg4NSxFPTE3MDkxNTY3NTU5NzAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export interface Decoded {
  name: string;
  picture: string;
}

const Router = () => {
  const meState = useAppSelector((state: RootState) => state.me);

  const dispatch = useAppDispatch();
  useEffect(
    () => {
      dispatch(fetchMe(meState.value.token));
    },
    [meState.value.authenticate],
  );

  if (meState.status === 'loading') {
    return (
      <Middle
        outlet={
          <CircularProgress size="10rem" style={{ color: '#8c2b25' }} />
        }
      />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/dashboard/campaigns" replace />}
        />
        <Route
          path="dashboard"
          element={(
            <ProtectedRoute
              auth={meState.value.authenticate === 'success'}
              authenticationPath="/login"
              outlet={<Dashboard />}
            />
          )}
        >
          <Route
            path="plans"
            element={<Plans />}
          />
          <Route
            path="campaigns"
            element={<Campaigns />}
          />
          <Route
            path="routers"
            element={<Routers />}
          />
          <Route
            path="terms"
            element={<Terms />}
          />
          <Route
            path="referrals"
            element={<Referrals />}
          />
          <Route
            path="active_reservations"
            element={<ActiveReservations />}
          />
          <Route
            path="commission_sales"
            element={<CommissionSales />}
          />
          <Route
            path="commission_sales/referral_report/:referralCode"
            element={<ReferralReportPage />}
          />
          <Route
            path="commission_sales/add_sales_person"
            element={<AddSalesPerson />}
          />
          <Route
            path="referrals/referral_report/:referralCode"
            element={<ReferralReportPage />}
          />
          <Route
            path="referrals/post_signup/:referralCode"
            element={<PostSignUpReferral />}
          />
          <Route
            path="referrals/demo"
            element={<ReferAFriendDemo />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route
          path="logout"
          element={<Logout />}
        />
      </Routes>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <Provider store={store}>
        <Router />
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
