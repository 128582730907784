import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  newPlan, fetchPlans, createPlan, updatePlan, deletePlan, Plan,
} from '../features/plans/plans';
import { RootState } from '../app/store';
import { Campaign, fetchCampaigns, selectCampaignsByPlanId } from '../features/campaigns/campaigns';

const collator = new Intl.Collator(undefined, { numeric: true });

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp<Plan>) => GridRowsProp<Plan>) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

interface PlanWithCampaign extends Plan{
  campaigns: Campaign[];
}

const AddToolbar = ({ setRows, setRowModesModel }: EditToolbarProps) => {
  const handleClick = () => {
    const plan = newPlan();
    setRows((oldRows) => [...oldRows, plan]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [plan.id]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Plan
      </Button>
    </GridToolbarContainer>
  );
};
const useStyles = makeStyles({
  editAction: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    padding: '5px 8px', // Add padding to action items
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  deleteAction: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    padding: '5px 8px', // Add padding to action items
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  greenRow: {
    backgroundColor: '#C8E6C9', // Pale Green
    color: 'black',
    fontFamily: 'Roboto, sans-serif', // Set a calming font
    padding: '10px', // Add padding around text
    fontSize: '14px', // Increase font size for readability
    '&:hover': {
      backgroundColor: '#A8E6CF', // Darker shade on hover
      fontWeight: 'bold',
    },
  },
  redRow: {
    backgroundColor: '#FAD4D8', // Blush Pink
    color: 'black',
    fontFamily: 'Roboto, sans-serif',
    padding: '10px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#FFC1B6',
      fontWeight: 'bold',
    },
  },
  actionItem: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    padding: '5px 8px', // Add padding to action items
    '&:hover': {
      fontWeight: 'bold',
    },
  },
});

const Plans = () => {
  const dispatch = useAppDispatch();
  const plansState = useAppSelector((state: RootState) => state.plans);

  const classes = useStyles();
  const campaignsByPlanId = useAppSelector(selectCampaignsByPlanId);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState(
    plansState.value.map((p) => ({ ...p, campaigns: campaignsByPlanId[p.id] })),
  );

  useEffect(() => { dispatch(fetchPlans()); }, []);
  useEffect(() => { dispatch(fetchCampaigns()); }, []);
  useEffect(() => setRows(
    plansState.value.map((p) => ({ ...p, campaigns: campaignsByPlanId[p.id] })),
  ), [plansState.value, campaignsByPlanId]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);

  const handleDeleteClick = (id: GridRowId) => () => {
    setSelectedRowId(id); // Store the row ID to delete
    setOpenDeleteModal(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = () => {
    const rowToDelete = rows.find((row) => row.id === selectedRowId);
    if (rowToDelete) {
      setRows(rows.filter((row) => row.id !== selectedRowId));
      dispatch(deletePlan(rowToDelete));
    }

    setOpenDeleteModal(false); // Close the dialog
    setSelectedRowId(null); // Clear the stored ID
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setSelectedRowId(null);
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel<PlanWithCampaign>) => {
    const oldRowIdx = rows.findIndex((r) => r.id === newRow.id);
    if (oldRowIdx < 0) {
      setRows(rows.map((r) => (r.id === '' ? newRow : r)));
      dispatch(createPlan(newRow));
    } else {
      setRows([...rows.slice(0, oldRowIdx), newRow, ...rows.slice(oldRowIdx + 1)]);
      dispatch(updatePlan(newRow));
    }
    return newRow;
  };

  const columns: GridColDef<PlanWithCampaign>[] = [
    {
      field: 'id',
      headerName: 'Id',
      editable: true,
      sortComparator: collator.compare,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
    },
    {
      field: 'rsp',
      headerName: 'RSP',
      editable: true,
    },
    {
      field: 'description1',
      headerName: 'Description 1',
      width: 200,
      editable: true,
    },
    {
      field: 'description2',
      headerName: 'Description 2',
      width: 200,
      editable: true,
    },
    {
      field: 'campaigns',
      headerName: 'Campaigns',
      editable: false,
      renderCell: ({ value }) => ((value || []) as Campaign[])
        .map((c) => c.name)
        .sort(collator.compare)
        .join(', '),
    },
    {
      field: 'speed',
      headerName: 'Speed',
      type: 'number',
      editable: true,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      editable: true,
    },
    {
      field: 'addressLIT',
      headerName: 'Show for LIT',
      type: 'boolean',
      editable: true,
    },
    {
      field: 'addressNonLIT',
      headerName: 'Show for non LIT',
      type: 'boolean',
      editable: true,
    },
    {
      field: 'published',
      headerName: 'Published',
      type: 'boolean',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [<GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(params.row.id)}
          />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className={classes.editAction}
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            className={classes.deleteAction}
            onClick={handleDeleteClick(params.row.id)}
          />,
        ];
      },
    },
  ];

  return (
    <div style={{
      display: 'flex', flexDirection: 'column',
    }}
    >
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <Toolbar />
      </Stack>
      <Dialog open={openDeleteModal} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this plan?
            <br />
            plan ID:
            {' '}
            <b>
              {selectedRowId}
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={3}>
        {' '}
        {/* Adjust padding as needed */}
        <DataGrid
          autoHeight
          getRowHeight={(params) => 'auto'}
          loading={plansState.status === 'loading'}
          rows={rows}
          columns={columns}
          getRowClassName={(params) => (params.row.published ? classes.greenRow : classes.redRow)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'published', sort: 'desc' }, { field: 'id', sort: 'asc' }],
            },
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          pageSizeOptions={[100]}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          components={{
            Toolbar: AddToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Box>
    </div>
  );
};

export default Plans;
