import React from 'react';

export type Props = {
  outlet: JSX.Element;
};

const Middle = ({ outlet }: Props) => (
  <div
    style={{
      // do your styles depending on your needs.
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {outlet}
  </div>
);

export default Middle;
