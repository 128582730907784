import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Term {
  id: string;
  name: string;
  rsp: string;
  description1: string;
  description2: string;
  description3: string;
  mode: string;
  cost: string;
}

export interface termsState {
  value: Array<Term>;
  status: 'idle' | 'loading' | 'failed';
}

export const newTerm = () => ({
  id: '',
  name: '',
  rsp: 'pineapple',
  description1: '',
  description2: '',
  description3: '',
  mode: '',
  cost: '0.00',
});

const initialState: termsState = {
  value: [],
  status: 'idle',
};

export const fetchTerms = createAsyncThunk('terms/fetchTerms', async () => {
  const response = await Client.get<Term[]>('/api/v1/admin/terms');
  return response.data;
});

export const createTerm = createAsyncThunk<Term, Term, { state: RootState }>(
  'terms/createTerm',
  async (term: Term) => {
    const response = await Client.post<Term>(
      '/api/v1/admin/terms/create',
      term,
    );
    return response.data;
  },
);

export const updateTerm = createAsyncThunk<Term, Term, { state: RootState }>(
  'terms/updateTerm',
  async (term: Term) => {
    const response = await Client.post<Term>(
      '/api/v1/admin/terms/update',
      term,
    );
    return response.data;
  },
);

export const deleteTerm = createAsyncThunk<Term, Term, { state: RootState }>(
  'terms/deleteTerm',
  async (term: Term) => {
    await Client.post<Term>('/api/v1/admin/terms/delete', term);
    return term;
  },
);

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTerms.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchTerms.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createTerm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createTerm.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createTerm.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateTerm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTerm.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.map((v) => (v.id === action.payload.id ? action.payload : v));
      })
      .addCase(updateTerm.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteTerm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteTerm.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [] : [v]));
      })
      .addCase(deleteTerm.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectTerms = (state: RootState) => state.terms.value;
export default termsSlice.reducer;
