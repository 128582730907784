import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  newRouter, fetchRouters, createRouter, updateRouter, deleteRouter, Router,
} from '../features/routers/routers';
import { RootState } from '../app/store';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp<Router>) => GridRowsProp<Router>) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

const AddToolbar = ({ setRows, setRowModesModel }: EditToolbarProps) => {
  const handleClick = () => {
    const router = newRouter();
    setRows((oldRows) => [...oldRows, router]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [router.id]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Router
      </Button>
    </GridToolbarContainer>
  );
};

const Routers = () => {
  const routersState = useAppSelector((state: RootState) => state.routers);
  const dispatch = useAppDispatch();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState(routersState.value);

  useEffect(() => { dispatch(fetchRouters()); }, []);
  useEffect(() => setRows(routersState.value), [routersState.value]);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      setRows(rows.filter((row) => row.id !== id));
      dispatch(deleteRouter(rowToDelete));
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel<Router>) => {
    const oldRowIdx = rows.findIndex((r) => r.id === newRow.id);
    if (oldRowIdx < 0) {
      setRows(rows.map((r) => (r.id === '' ? newRow : r)));
      dispatch(createRouter(newRow));
    } else {
      setRows([...rows.slice(0, oldRowIdx), newRow, ...rows.slice(oldRowIdx + 1)]);
      dispatch(updateRouter(newRow));
    }
    return newRow;
  };

  const columns: GridColDef<Router>[] = [
    {
      field: 'id',
      headerName: 'Id',
      editable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      width: 200,
    },
    {
      field: 'rsp',
      headerName: 'RSP',
      editable: true,
    },
    {
      field: 'description1',
      headerName: 'Description 1',
      editable: true,
      width: 700,
    },
    {
      field: 'description2',
      headerName: 'Description 2',
      editable: true,
      width: 200,
    },
    {
      field: 'description3',
      headerName: 'Description 3',
      editable: true,
      width: 200,
    },
    {
      field: 'mode',
      headerName: 'Mode',
      type: 'string',
      editable: true,
      width: 200,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      editable: true,
      width: 80,
    },
    {
      field: 'published',
      headerName: 'Published',
      type: 'boolean',
      editable: true,
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [<GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(params.row.id)}
          />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ height: 800 }}>
      <Toolbar />
      <DataGrid
        autoHeight
        loading={routersState.status === 'loading'}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'speed', sort: 'asc' }],
          },
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        pageSizeOptions={[100]}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => console.error(error)}
        components={{
          Toolbar: AddToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
};

export default Routers;
