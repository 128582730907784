import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { StandardTextFieldProps } from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import { Client } from '../../http';

interface AdornmentProps {
    loading: boolean,
    error: boolean,
    dirty: boolean
}

const Adornment = ({ loading, error, dirty }: AdornmentProps) => {
  if (loading) return <CircularProgress size={25} color="inherit" />;
  if (!error) return <CheckCircleIcon color="success" />;
  if (dirty) return <ErrorIcon color="error" />;
  return null;
};

const couponValid = async (coupon: string) => {
  try {
    await Client.post('/api/v1/admin/campaigns/coupon', { coupon_id: coupon });
    return true;
  } catch (err) {
    return false;
  }
};

const errorMessage = 'Coupon is invalid';

type CouponFieldProps = StandardTextFieldProps & {
  defaultValue?: string,
  onValidate: (v: string, valid: boolean) => void,
}

const CouponField = ({
  defaultValue = '',
  onValidate = () => null,
  label,
  required,
  fullWidth,
}: CouponFieldProps) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const [debouncedValue] = useDebounce(inputValue, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (!dirty && debouncedValue === '') return;

    setLoading(true);
    couponValid(debouncedValue).then((valid) => {
      setLoading(false);
      setDirty(true);
      setError(!valid);
      onValidate(debouncedValue, valid);
    });
  }, [debouncedValue]);

  return (
    <FormControl fullWidth={fullWidth} variant="outlined">
      <InputLabel
        sx={{ backgroundColor: 'white', padding: '0 0.25em' }}
        htmlFor="coupon-input"
      >
        { label }
        { required ? ' *' : '' }
      </InputLabel>
      <OutlinedInput
        error={dirty && error}
        required={required}
        value={inputValue}
        onChange={handleChange}
        id="coupon-input"
        aria-describedby="coupon-helper-text"
        endAdornment={<Adornment loading={loading} error={error} dirty={dirty} />}
      />
      <FormHelperText error={error} hidden={!dirty || !error} id="coupon-helper-text">
        { errorMessage }
      </FormHelperText>
    </FormControl>
  );
};

export default CouponField;
