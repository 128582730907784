import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Router {
  id: string;
  name: string;
  rsp: string;
  description1: string;
  description2: string;
  description3: string;
  mode: string;
  published: boolean;
  cost: string;
}

export interface routersState {
  value: Array<Router>;
  status: 'idle' | 'loading' | 'failed';
}

export const newRouter = () => ({
  id: '',
  name: '',
  rsp: 'pineapple',
  description1: '',
  description2: '',
  description3: '',
  mode: '',
  published: false,
  cost: '0.00',
});

const initialState: routersState = {
  value: [],
  status: 'idle',
};

export const fetchRouters = createAsyncThunk(
  'routers/fetchRouters',
  async () => {
    const response = await Client.get<Router[]>('/api/v1/admin/routers');
    return response.data;
  },
);

export const createRouter = createAsyncThunk<
  Router,
  Router,
  { state: RootState }
>('routers/createRouter', async (router: Router) => {
  const response = await Client.post<Router>(
    '/api/v1/admin/routers/create',
    router,
  );
  return response.data;
});

export const updateRouter = createAsyncThunk<
  Router,
  Router,
  { state: RootState }
>('routers/updateRouter', async (router: Router) => {
  const response = await Client.post<Router>(
    '/api/v1/admin/routers/update',
    router,
  );
  return response.data;
});

export const deleteRouter = createAsyncThunk<
  Router,
  Router,
  { state: RootState }
>('routers/deleteRouter', async (router: Router) => {
  await Client.post<Router>('/api/v1/admin/routers/delete', router);
  return router;
});

export const routersSlice = createSlice({
  name: 'routers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRouters.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchRouters.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createRouter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createRouter.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createRouter.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateRouter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateRouter.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.map((v) => (v.id === action.payload.id ? action.payload : v));
      })
      .addCase(updateRouter.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteRouter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteRouter.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [] : [v]));
      })
      .addCase(deleteRouter.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectRouters = (state: RootState) => state.routers.value;
export default routersSlice.reducer;
