import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Plan {
  id: string;
  name: string;
  rsp: string;
  description1: string;
  description2: string;
  speed: number;
  cost: string;
  addressLIT: boolean;
  addressNonLIT: boolean;
  published: boolean;
}

export interface plansState {
  value: Array<Plan>;
  status: 'idle' | 'loading' | 'failed';
}

export const newPlan = () => ({
  id: '',
  name: '',
  rsp: 'pineapple',
  description1: '',
  description2: '',
  speed: 0,
  cost: '0.00',
  addressLIT: true,
  addressNonLIT: false,
  published: false,
});

const initialState: plansState = {
  value: [],
  status: 'idle',
};

export const fetchPlans = createAsyncThunk('plans/fetchPlans', async () => {
  const response = await Client.get<Plan[]>('/api/v1/admin/plans');
  return response.data;
});

export const createPlan = createAsyncThunk<Plan, Plan, { state: RootState }>(
  'plans/createPlan',
  async (plan: Plan) => {
    const response = await Client.post<Plan>(
      '/api/v1/admin/plans/create',
      plan,
    );
    return response.data;
  },
);

export const updatePlan = createAsyncThunk<Plan, Plan, { state: RootState }>(
  'plans/updatePlan',
  async (plan: Plan) => {
    const response = await Client.post<Plan>(
      '/api/v1/admin/plans/update',
      plan,
    );
    return response.data;
  },
);

export const deletePlan = createAsyncThunk<Plan, Plan, { state: RootState }>(
  'plans/deletePlan',
  async (plan: Plan) => {
    await Client.post<Plan>('/api/v1/admin/plans/delete', plan);
    return plan;
  },
);

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPlans.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createPlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createPlan.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updatePlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.map((v) => (v.id === action.payload.id ? action.payload : v));
      })
      .addCase(updatePlan.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deletePlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [] : [v]));
      })
      .addCase(deletePlan.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectPlans = (state: RootState) => state.plans.value;
export default plansSlice.reducer;
