import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { RWebShare } from 'react-web-share';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  fetchReferrals, deleteReferrals, Referral,
} from '../features/referrals/refarrals';
import { RootState } from '../app/store';
import { Client } from '../http';
import CopyToClipboardButton from './CopyToClipBoard';

const ReferAFriendDemo = () => {
  const p = useParams();
  const referralCode = p.referralCode as string;

  const referralState = useAppSelector((state: RootState) => state.referrals);
  const dispatch = useAppDispatch();

  const cbUrl = window.location.host === 'marketing.support.dgtek.net' ? 'https://pineapple.chargebee.com/d/customers' : 'https://pineapple-test.chargebee.com/d/customers';

  useEffect(() => { dispatch(fetchReferrals()); }, []);

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [referrer, setReferrer] = useState('');
  const [refereeEmail, setRefereeEmail] = useState('');
  const [referee, setReferee] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = async () => {
    try {
      const response = await Client.post('https://api.sendgrid.com/v3/mail/send ', {
        personalizations: [
          {
            to: [
              {
                email: refereeEmail,
                name: referee,
              },
            ],
            subject: 'Your friend referred you to Pineapple!',
          },
        ],
        content: [
          {
            type: 'text/plain',
            value: `Hi ${referee}, you friend ${referrer} has referred you to pineapple, please signup using a link https://signup.master.pineapple.net.au/?referral=${code}`,
          },
        ],
        from: {
          email: 'appdev@dgtek.net',
        },
        reply_to: {
          email: 'appdev@dgtek.net',
          name: referrer,
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer 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',
        }, // TODO please delete this key and rotate it
      });
      setEmailSent(true);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCode = async () => {
    try {
      const response = await Client.get(`/api/v1/pineapple/refer_a_friend/${email}`);
      setCode(response.data.referral_code);
      setReferrer(response.data.first_name);
      console.log('RESPONSE', response.data.referral_code);
    } catch (err) {
      setCode('Unable to generate your referral code, email not found.');
    }
  };

  return (
    <Box component="span" sx={{ p: 0, border: '1px dashed grey' }}>
      <Grid container xs={12} rowSpacing={6} spacing={1}>
        <Grid item xs={2} />
        <Grid item xs={4}>
          <Box sx={{ marginTop: 8 }}>
            <TextField
              fullWidth
              label="Please enter your email"
              id="fullWidth"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ marginTop: 3 }}>
            <br />
            <br />
            <Button style={{ height: 55 }} onClick={fetchCode} variant="contained">Generate referral code</Button>
          </Box>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          {code === 'Unable to generate your referral code, email not found.' || code === ''
            ? <Typography variant="h5" gutterBottom>{code}</Typography>
            : (
              <>
                <Typography variant="h5" gutterBottom>Your code:</Typography>
                {' '}
                <Typography variant="h2" gutterBottom>{code}</Typography>
              </>
            )}
        </Grid>
        <Grid item xs={7}>
          {code !== 'Unable to generate your referral code, email not found.' && code !== ''
            && <CopyToClipboardButton textToCopy={code} />}
          {code !== 'Unable to generate your referral code, email not found.' && code !== ''
            && (
              <RWebShare
                data={{
                  text: 'Pineapple Referral Program',
                  url: `${window.location.protocol}//${window.location.host}/?referral_code=${code}`,
                  title: 'Pineapple Referral Program',
                }}
                onClick={() => console.log('shared successfully!')}
              >
                <IconButton sx={{
                  alignSelf: 'center',
                }}
                >
                  {' '}
                  <ShareIcon />
                  {' '}

                </IconButton>
              </RWebShare>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferAFriendDemo;
