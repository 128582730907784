import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { uuidv7 } from 'uuidv7';
import { Client } from '../../http';
import { RootState } from '../../app/store';
import { Plan } from '../plans/plans';
import { Building } from '../buildings/buildings';

export const rfc3339 = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export interface Campaign {
  id: string;
  name: string;
  rsp: string;
  startDate: string;
  endDate: string;
  monthsFree?: number;
  couponCode?: string;
  terms: string;
  plans: Plan[];
  buildings: Building[];
}

export interface campaignsState {
  value: Array<Campaign>;
  status: 'idle' | 'loading' | 'failed';
}

export const newCampaign: () => Campaign = (now = dayjs()) => ({
  id: uuidv7(),
  name: '',
  rsp: 'Pineapple',
  startDate: now.startOf('day').format(rfc3339),
  endDate: now.endOf('day').add(1, 'month').format(rfc3339),
  monthsFree: 1,
  couponCode: '',
  terms: '',
  plans: [] as Plan[],
  buildings: [] as Building[],
});

const initialState: campaignsState = {
  value: [],
  status: 'idle',
};

export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async () => {
    const response = await Client.get<Campaign[]>('/api/v1/admin/campaigns');
    return response.data;
  },
);

export const createCampaign = createAsyncThunk<
  Campaign,
  Campaign,
  { state: RootState }
>('campaigns/createCampaign', async (campaign: Campaign) => {
  const response = await Client.post<Campaign>(
    '/api/v1/admin/campaigns/create',
    campaign,
  );
  return response.data;
});

export const updateCampaign = createAsyncThunk<
  Campaign,
  Campaign,
  { state: RootState }
>('campaigns/updateCampaign', async (campaign: Campaign) => {
  const response = await Client.post<Campaign>(
    '/api/v1/admin/campaigns/update',
    campaign,
  );
  return response.data;
});

export const deleteCampaign = createAsyncThunk<
  Campaign,
  Campaign,
  { state: RootState }
>('campaigns/deleteCampaign', async (campaign: Campaign) => {
  await Client.post<Campaign>('/api/v1/admin/campaigns/delete', campaign);
  return campaign;
});

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.map((c) => ({
          ...c,
          plans: c.plans || [],
          buildings: c.buildings || [],
        }));
      })
      .addCase(fetchCampaigns.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push({
          ...action.payload,
          plans: action.payload.plans || [],
          buildings: action.payload.buildings || [],
        });
      })
      .addCase(createCampaign.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.status = 'idle';
        const campaign = {
          ...action.payload,
          plans: action.payload.plans || [],
          buildings: action.payload.buildings || [],
        };
        state.value = state.value.map((v) => (v.id === campaign.id ? campaign : v));
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [] : [v]));
      })
      .addCase(deleteCampaign.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectCampaigns = (state: RootState) => state.campaigns.value;
export const selectCampaignsByPlanId = createSelector(
  [selectCampaigns],
  (campaigns) => campaigns.reduce((acc, campaign) => {
    campaign.plans.forEach((p) => {
      if (acc[p.id]) {
        acc[p.id].push(campaign);
      } else {
        acc[p.id] = [campaign];
      }
    });
    return acc;
  }, {} as { [id: string]: Campaign[] }),
);
export default campaignsSlice.reducer;
