import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { RWebShare } from 'react-web-share';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  fetchReferrals, deleteReferrals, Referral,
} from '../features/referrals/refarrals';
import { RootState } from '../app/store';
import { Client } from '../http';
import CopyToClipboardButton from './CopyToClipBoard';

const AddSalesPerson = () => {
  const p = useParams();
  const referralCode = p.referralCode as string;

  const referralState = useAppSelector((state: RootState) => state.referrals);
  const dispatch = useAppDispatch();

  const cbUrl = window.location.host === 'marketing.support.dgtek.net' ? 'https://pineapple.chargebee.com/d/customers' : 'https://pineapple-test.chargebee.com/d/customers';

  useEffect(() => { dispatch(fetchReferrals()); }, []);

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [emailErr, setEmailErr] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);

  const fetchCode = async () => {
    try {
      const response = await Client.get(`/api/v1/admin/referrals/sales/${email}/${firstName}/${lastName}`);
      setCode(response.data.referral_code);
      console.log('RESPONSE', response.data.referral_code);
    } catch (err) {
      setCode('Unable to generate your referral code, email not found.');
    }
  };

  const emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <Box component="span" sx={{ p: 0, border: '1px dashed grey' }}>
      <Grid container xs={12} rowSpacing={6} spacing={1}>
        <Grid item xs={2} />
        <Grid item xs={4}>
          <Box sx={{ marginTop: 8 }}>
            <TextField
              fullWidth
              label="Please enter salesperson Email - must be valid, e.g. user@example.com"
              id="fullWidth"
              error={emailErr}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                if (!email.match(emailRe)) {
                  setEmailErr(true);
                } else {
                  setEmailErr(false);
                }
              }}
            />
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <TextField
              fullWidth
              label="Please enter salesperson First Name"
              id="fullWidth"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <TextField
              fullWidth
              label="Please enter salesperson Last Name"
              id="fullWidth"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <br />
            <br />
            <Button style={{ height: 55 }} onClick={fetchCode} variant="contained" disabled={emailErr}>Generate Sales Commission code</Button>
          </Box>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2} />
        <Grid item xs={3}>
          {code === 'Unable to generate your referral code, email not found.' || code === ''
            ? <Typography variant="h5" gutterBottom>{code}</Typography>
            : (
              <>
                <Typography variant="h5" gutterBottom>Your code:</Typography>
                {' '}
                <Typography variant="h2" gutterBottom>{code}</Typography>
              </>
            )}
        </Grid>
        <Grid item xs={7}>
          {code !== 'Unable to generate your referral code, email not found.' && code !== ''
            && <CopyToClipboardButton textToCopy={code} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSalesPerson;
