import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import JWTDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  Outlet,
} from 'react-router-dom';
import ResponsiveAppBar from './TopMenu';
import '../index.css';

export interface Decoded {
  name: string;
  picture: string;
}

const Dashboard = () => {
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [cookies] = useCookies(['jolly']);

  useEffect(() => {
    const token = cookies.jolly;
    if (token) {
      const decoded: Decoded = JWTDecode(token);
      setPicture(decoded.picture);
      setName(decoded.name);
    }
  }, []);

  return (
    <Box sx={{ height: '100vh', flexGrow: 1 }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} alignItems="left">
          <ResponsiveAppBar avatarLink={picture} avatarAltText={name} />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
