import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Reservation {
  reservation_id: string;
  external_id: string;
  expires_at: string;
  sid: string;
  address: string;
}

export interface reservationsState {
  value: Array<Reservation>;
  status: 'idle' | 'loading' | 'failed';
}

export const newReservations = () => ({
  reservation_id: '',
  external_id: 0,
  expires_at: '',
  sid: '',
  address: '',
});

const initialState: reservationsState = {
  value: [],
  status: 'idle',
};

export const fetchActiveReservations = createAsyncThunk(
  'active_reservations/fetchActiveReservations',
  async () => {
    const response = await Client.get<Reservation[]>('/api/v1/admin/reservations');
    return response.data;
  },
);

export const activeReservationsSlice = createSlice({
  name: 'active_reservations',
  initialState,
  reducers: {
    removeReservation(state, action: PayloadAction<Reservation>) {
      state.value.filter((res) => res.expires_at !== action.payload.expires_at);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveReservations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchActiveReservations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...action.payload];
      })
      .addCase(fetchActiveReservations.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectActiveReservations = (state: RootState) => state.active_reservations.value;
export default activeReservationsSlice.reducer;
export const { removeReservation } = activeReservationsSlice.actions;
