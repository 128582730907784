import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

interface Search {
  email: string
  subId: string
  firstName: string
  lastName: string
  code: string
}

export interface Subscription {
  id: string
  plan_id: string
  start_date: number,
  customer_id: string,
  plan_amount: number,
  status: string,
  created_at: number,
  started_at: number,
  activated_at: number,
  custom_field: {
    cf_customer_reference_number: string
  },
}

export interface SubscriptionHubspot {
  subscription: Subscription,
  hubspot: {
    id: string,
    properties: {
      dealname: string,
      amount: string,
      closedate: string,
      createdate: string,
      dealstage: string,
    }
  }
}

export interface SearchState {
  value: Array<SubscriptionHubspot>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SearchState = {
  value: [],
  status: 'idle',
};

export const searchSubscriptions = createAsyncThunk(
  'search/searchSubscriptions',
  async (search: Search) => {
    const response = await Client.post<SubscriptionHubspot[]>('/api/v1/admin/referrals/search', { ...search });
    return response.data;
  },
);

export const searchSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(searchSubscriptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchSubscriptions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...action.payload];
      })
      .addCase(searchSubscriptions.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectSearch = (state: RootState) => state.search.value;
export default searchSlice.reducer;
