import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridColDef,
  GridRowId,
  GridPanel,
  GridFilterPanel,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import LinearProgress from '@mui/material/LinearProgress';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import '../../App.css';
import {
  Campaign,
  createCampaign,
  deleteCampaign,
  fetchCampaigns,
  newCampaign,
  updateCampaign,
} from '../../features/campaigns/campaigns';
import { fetchPlans, Plan } from '../../features/plans/plans';
import { Building } from '../../features/buildings/buildings';
import { RootState } from '../../app/store';
import { NewCampaignModal } from './NewCampaignModal';

const collator = new Intl.Collator(undefined, { numeric: true });

const FilterPanel = () => (
  <GridPanel
    open
    placement="bottom-end"
  >
    <GridFilterPanel />
  </GridPanel>
);

const AddToolbar: React.FC<{
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>
}> = ({ setModalState }) => (
  <GridToolbarContainer>
    <Grid container sm={12}>
      <Grid item sm={6}>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalState(
            { open: true, function: 'Add' },
          )}
        >
          Add Campaign
        </Button>
      </Grid>
      <Grid item sm={6}>
        <Box display="flex" justifyContent="flex-end">
          <GridToolbarFilterButton sx={{ marginRight: '2em' }} />
          <GridToolbarQuickFilter />
        </Box>
      </Grid>
    </Grid>
  </GridToolbarContainer>
);

interface ModalState {
    open: boolean,
    function: 'Add' | 'Edit'
}

const Campaigns = () => {
  const dispatch = useAppDispatch();
  const campaignsState = useAppSelector((state: RootState) => state.campaigns);
  const plansState = useAppSelector((state: RootState) => state.plans);
  const [rows, setRows] = useState(campaignsState.value);
  const [modalState, setModalState] = useState({ open: false, function: 'Add' });
  const [initialCampaign, setInitialCampaign] = useState(newCampaign());

  useEffect(() => { dispatch(fetchCampaigns()); }, []);
  useEffect(() => { dispatch(fetchPlans()); }, []);
  useEffect(() => setRows(campaignsState.value), [campaignsState.value]);

  const handleEditClick = (id: GridRowId) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    if (rowToEdit) {
      setInitialCampaign(rowToEdit);
      setModalState({ open: true, function: 'Edit' });
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      setRows(rows.filter((row) => row.id !== id));
      dispatch(deleteCampaign(rowToDelete));
    }
  };

  const statusToColorMap = {
    active: 'success',
    pending: 'info',
    ended: 'default',
  } as Record<string, any>;

  const columns: GridColDef<Campaign>[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      renderCell: ({ value }) => <Chip color={statusToColorMap[value]} label={value} />,
      valueGetter: (params) => {
        const { row } = params;
        if (dayjs().isAfter(row.startDate) && dayjs().isBefore(row.endDate)) {
          return 'active';
        }

        if (dayjs().isAfter(row.endDate)) {
          return 'ended';
        }

        return 'pending';
      },
    },
    {
      field: 'rsp',
      headerName: 'RSP',
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      renderCell: ({ value }) => (
        <DateField
          contentEditable={false}
          value={dayjs(value)}
          sx={{ '& fieldset': { border: 'none' } }}
        />
      ),
      width: 150,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      renderCell: ({ value }) => (
        <DateField
          contentEditable={false}
          value={dayjs(value)}
          sx={{
            '& fieldset': { border: 'none' },
          }}
        />
      ),
      width: 150,
    },
    {
      field: 'buildings',
      headerName: 'Buildings',
      renderCell: ({ value }) => {
        const buildings = value.map((b: Building) => b.id);

        if (buildings.length <= 2) {
          return buildings.join(', ');
        }

        return `${buildings.slice(0, 2).join(', ')} and ${buildings.length - 2} more`;
      },
      filterable: false,
      width: 400,
    },
    {
      field: 'monthsFree',
      headerName: 'Free Months',
      width: 150,
    },
    {
      field: 'couponCode',
      headerName: 'Coupon Code',
      width: 150,
    },
    {
      field: 'plans',
      headerName: 'Plans',
      renderCell: ({ value }) => ([...value] as Plan[]) // don't sort in place
        .sort((p1, p2) => collator.compare(p1.id, p2.id))
        .map((p) => p.name)
        .join(', '),
      filterable: false,
      width: 400,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params.row.id)}
          color="inherit"
        />,
        <GridActionsCellItem
          disabled
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(params.row.id)}
        />,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ height: 800 }}>
        <Toolbar />
        <DataGrid
          autoHeight
          loading={campaignsState.status === 'loading'}
          rows={rows}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'startDate', sort: 'desc' }],
            },
            filter: {
              filterModel: {
                items: [
                  { field: 'status', operator: 'isAnyOf', value: ['pending', 'active'] },
                ],
              },
            },
          }}
          pageSizeOptions={[100]}
          onProcessRowUpdateError={(error) => console.error(error)}
          components={{
            Toolbar: AddToolbar,
            LoadingOverlay: LinearProgress,
            FilterPanel,
          }}
          componentsProps={{
            toolbar: { setModalState },
          }}
        />
      </Box>
      <NewCampaignModal
        key={initialCampaign.id} // ensure that a new state is made when there is a new campaign
        initialCampaign={initialCampaign}
        avaliablePlans={plansState.value}
        open={modalState.open}
        title={`${modalState.function} Campaign`}
        onModalClose={(campaign?: Campaign) => {
          if (campaign) {
            const idx = rows.findIndex((row) => row.id === campaign.id);
            if (idx === -1) {
              dispatch(createCampaign(campaign));
            } else {
              dispatch(updateCampaign(campaign));
            }
          }
          setInitialCampaign(newCampaign());
          setModalState({ open: false, function: 'Add' });
        }}
      />
    </>
  );
};

export default Campaigns;
