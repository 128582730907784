import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { darken, lighten, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  gridFilteredSortedRowIdsSelector,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridToolbar,
  MuiBaseEvent,
  MuiEvent,
  selectedGridRowsSelector,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Avatar, Checkbox, FormControlLabel, Icon, Modal, Typography,
} from '@mui/material';
import {
  ArrowUpward, CheckBox, Clear, GroupAdd, Pause, QuestionMark, Stop, Warning,
} from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  fetchReferrals, deleteReferrals, Referral, fetchCommissionSales, redeemReferral, deleteReferralBySk, fetchReferralReport,
} from '../features/referrals/refarrals';
import { RootState } from '../app/store';
import { getOurDealStage } from '../utils';

const modalStyle = {
  position: 'absolute' as const,
  bgcolor: 'grey',
  top: '20%',
  left: '30%',
  width: 500,
  height: 250,
  color: 'white',
  borderRadius: '10px',
  p: 4,
};

interface OurIconProps {
  status: string
}
const OurIcon = ({ status }: OurIconProps) => {
  switch (status) {
    case 'future':
      return <ArrowUpward htmlColor="orange" />;
    case 'cancelled':
      return <Clear htmlColor="red" />;
    case 'paused':
      return <Pause htmlColor="grey" />;
    case 'active':
      return <CheckBox htmlColor="green" />;
    default:
      return <Warning htmlColor="orange" />;
  }
};

const getBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7));

const getHoverBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));

const getSelectedBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5));

const getSelectedHoverBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .highlight-bad-record': {
    backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
})) as typeof DataGrid;

const ReferralReportPage = () => {
  const p = useParams();
  const referralCode = p.referralCode as string;

  const [showRedeem, setShowRedeem] = useState(false);
  const [redeemInChargeBee, setRedeemInChargeBee] = useState('no');
  const [searchParams, setSearchParams] = useSearchParams();
  const showPending = (/true/i).test(searchParams.get('showPending') || '');

  const referralState = useAppSelector((state: RootState) => state.referrals);
  const dispatch = useAppDispatch();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState(referralState.value);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [serviceID, setServiceID] = useState('');
  const [referralType, setReferralType] = useState(0);
  const [hubspotDealStage, setHubspotDealStage] = useState('unknown');
  const [hubspotDealId, setHubspotDealId] = useState('unknown');
  const [cbSubId, setCbSubId] = useState('unknown');
  const [cbID, setCBID] = useState('');
  const [cbStatus, setCbStatus] = useState('unknown');

  const cbUrl = window.location.host === 'marketing.support.dgtek.net' ? 'https://pineapple.chargebee.com/d/customers' : 'https://pineapple-test.chargebee.com/d/customers';

  const signupUrl = window.location.host === 'marketing.support.dgtek.net' ? 'https://signup.pineapple.net.au' : 'https://signup.master.pineapple.net.au';
  const isCommission = window.location.pathname.includes('commission_sales');

  useEffect(() => {
    dispatch(fetchReferralReport({
      sales: isCommission,
      showPending,
      code: referralCode,
    }));
  }, []);
  useEffect(() => {
    setRows(referralState.value);
    const filteredByCode = referralState.value.filter((referral) => referral.referral_code === referralCode && referral.referral_code === referral.sk);
    if (filteredByCode.length > 0) {
      setEmail(filteredByCode[0].referrer_email);
      setFirstName(filteredByCode[0].first_name);
      setLastName(filteredByCode[0].last_name);
      setCBID(filteredByCode[0].cb_id);
      setServiceID(filteredByCode[0].service_id);
      setReferralType(filteredByCode[0].referral_object_type);
      setHubspotDealId(filteredByCode[0].hubspot_deal_id);
      setHubspotDealStage(filteredByCode[0].hubspot_deal_stage);
      setCbSubId(filteredByCode[0].cb_sub_id);
      setCbStatus(filteredByCode[0].cb_active);
    }
  }, [referralState.value]);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const [loadingRedeem, setLoadingRedeem] = useState(false);

  const handleRedeemClose = () => setShowRedeem(false);
  const handleRedeem = (referral: Referral) => async () => {
    setLoadingRedeem(true);
    await dispatch(redeemReferral({ ...referral, redeemInChargeBee }));
    setRedeemInChargeBee('no');
    setLoadingRedeem(false);
    setShowRedeem(false);
  };

  const handleRowEditStop = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const rowToDelete = rows.find((row) => row.sk === id);
    if (rowToDelete) {
      // setRows(rows.filter((row) => row.sk !== id));
      console.log('ROWS', rowToDelete);
      dispatch(deleteReferralBySk(rowToDelete));
    }
  };

  const getSelectedRowsToExport = ({
    apiRef,
  }: any): GridRowId[] => {
    const selectedRowIds = selectedGridRowsSelector(apiRef);
    if (selectedRowIds.size > 0) {
      return Array.from(selectedRowIds.keys());
    }

    return gridFilteredSortedRowIdsSelector(apiRef);
  };

  const columns: GridColDef<Referral>[] = [
    {
      field: 'building_id',
      headerName: 'Building ID',
      editable: false,
      width: 160,
      sortable: true,
    },
    {
      field: 'service_id',
      headerName: 'Service ID',
      editable: false,
      width: 200,
    },
    {
      field: 'sk',
      headerName: 'Referee Email',
      editable: false,
      width: 200,
      valueFormatter: (params) => (params.value.split('#', 1)[0]),
    },
    {
      field: 'id', headerName: 'CB SubId', width: 250, renderCell: (params) => (<a target="_blank" href={`https://pineapple.chargebee.com/d/subscriptions/${params.row?.cb_sub_id}`} rel="noreferrer">{params.row?.cb_sub_id}</a>),
    },
    {
      field: 'hubspot_deal_id',
      headerName: 'Hubspot Deal ID',
      width: 250,
      renderCell: (params) => (<a target="_blank" href={`https://app.hubspot.com/contacts/8498425/record/0-3/${params.row?.hubspot_deal_id}`} rel="noreferrer">{getOurDealStage(params.row?.hubspot_deal_stage)}</a>),
    },
    {
      field: 'cb_id',
      headerName: 'Redeem',
      editable: false,
      renderCell: (cellValues) => {
        if (cellValues.hasFocus === true) {
          return (

            <>
              <Modal
                sx={modalStyle}
                open={showRedeem}
                onClose={handleRedeemClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure that you want to redeem this pending referral ?
                  </Typography>
                  <br />

                  <FormControlLabel control={<Checkbox onClick={() => setRedeemInChargeBee('stripe')} />} label="Redeem in Chargebee" />

                  <Typography>
                    SID:&nbsp;
                    <b>
                      {cellValues.row.service_id}
                    </b>
                  </Typography>

                  <Typography>
                    Record:&nbsp;
                    <b>
                      {cellValues.row.sk}
                    </b>
                  </Typography>

                  <Typography>
                    Email:&nbsp;
                    <b>
                      {cellValues.row.referrer_email}
                    </b>
                  </Typography>

                  <div style={{ marginTop: '10px' }}>
                    <span style={{ display: 'block', float: 'left' }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleRedeem(cellValues.row)}
                      >
                        Proceed
                      </Button>
                    </span>
                    <span style={{ display: 'block', float: 'right' }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={handleRedeemClose}
                      >
                        Cancel
                      </Button>
                    </span>
                  </div>
                </Box>
              </Modal>
              <Button
                variant="outlined"
                size="small"
                color={showPending ? 'error' : 'primary'}
                href={!showPending ? `${cbUrl}/${cellValues.row.sk}` : undefined}
                onClick={showPending ? () => setShowRedeem(true) : undefined}
              >
                {showPending ? 'Redeem' : 'View in Chargebee'}
              </Button>
            </>
          );
        } return <GroupAdd />;
      },
      width: 80,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: false,
      width: 140,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      editable: false,
      width: 100,
    },
    {
      field: 'cb_active',
      headerName: 'Chargebee status',
      editable: false,
      width: 140,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OurIcon status={params.row.cb_active} />}
          label="Go to chargebee"
          onClick={() => {
            window.open(`${cbUrl.replaceAll('customers', 'subscriptions')}/${params?.row?.cb_sub_id}`);
          }}
        />,
      ],
    },
    {
      field: 'cb_sub_start_date',
      headerName: 'Start date',
      editable: false,
      width: 140,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Delete',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(params.row.sk)}
        />,
      ],
    },
  ];

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => ({
    sx: {
      bgcolor: stringToColor(name),
      width: 56,
      height: 56,
    },
    src: name === 'Ja Gemmell' ? 'https://avatars.githubusercontent.com/u/3252430?v=4' : undefined,
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  });

  return (

    <Box component="span" sx={{ p: 0, border: '1px dashed grey' }}>
      <Grid container rowSpacing={6} spacing={0}>
        <Grid item xs={2} />
        <Grid item xs={1}>
          <Box sx={{ marginTop: 12 }}>
            <Avatar
              {...stringAvatar(`${firstName} ${lastName}`)}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ marginTop: 10 }}>
            <h2>
              {firstName}
              {' '}
              {lastName}
            </h2>
            <p>{email}</p>
            { isCommission ? null : <p>{serviceID}</p>}
            <p>
              Referral code:
              {' '}
              <Chip label={referralCode} />
            </p>
            <p>
              Hubspot:
              {' '}
              <Chip label={getOurDealStage(hubspotDealStage)} onClick={() => window.open(`https://app.hubspot.com/contacts/8498425/record/0-3/${hubspotDealId}`)} />
            </p>
            <p>
              Chargebee:
              {' '}
              <Chip label={cbStatus} onClick={() => window.open(`https://pineapple.chargebee.com/d/subscriptions/${cbSubId}`)} />
            </p>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ marginTop: 5 }}>
            <br />
            <br />
            { isCommission ? null : <Button href={`${cbUrl}/${cbID}`} variant="contained">View in Chargebee</Button> }
            {' '}
            { showPending ? <Button href={`${referralCode}`} variant="contained">Show Redeemed</Button> : <Button href={`${referralCode}?showPending=true`} variant="contained">Show Pending</Button> }
            <br />
            <br />
            <Button target="_blank" href={`${signupUrl}?referralCode=${referralCode}`} variant="outlined">Visit Referral Link</Button>
            <br />
            <br />
            <Button href={`/dashboard/referrals/post_signup/${referralCode}${window.location.pathname.includes('sales') ? '?sales=true' : ''}`} variant="contained" color="secondary">Post SignUp Referral</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            height: 350, width: '80%', margin: 'auto',
          }}
          >
            <StyledDataGrid
              getRowId={(row) => row.sk}
              getRowClassName={(params) => (['active', 'cancelled', 'paused'].includes(params.row.cb_active) && showPending ? 'highlight-bad-record' : '')}
              autoHeight
              loading={referralState.status === 'loading' || loadingRedeem}
              rows={rows.filter((row) => (row.referral_code === referralCode
                && row.sk !== row.referrer_email
                && row.referral_code !== row.sk))}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'speed', sort: 'asc' }],
                },
              }}
              editMode="row"
              rowModesModel={rowModesModel}
              pageSizeOptions={[100]}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              onProcessRowUpdateError={(error) => console.error(error)}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferralReportPage;
