import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const Logout = () => {
  const [_, removeCookie] = useCookies(['jolly']);

  const handleRemove = () => {
    removeCookie('jolly', { path: '/' });
  };

  useEffect(() => {
    handleRemove();
    window.location.replace('/login');
  });

  return (
    <div style={{ flexGrow: '1' }}>
      You have been logged out
    </div>
  );
};

export default Logout;
