import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowModesModel,
  selectedGridRowsSelector,
  gridFilteredSortedRowIdsSelector,
  GridToolbar,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { act } from 'react-dom/test-utils';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  activeReservationsSlice,
  fetchActiveReservations, removeReservation, Reservation,
} from '../features/active_reservations/active_reservations';
import { RootState } from '../app/store';
import Countdown from './CountDown';

const ActiveReservations = () => {
  const activeReservationState = useAppSelector((state: RootState) => state.active_reservations);
  const dispatch = useAppDispatch();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState(activeReservationState.value);

  useEffect(() => { dispatch(fetchActiveReservations()); }, []);
  useEffect(() => setRows(activeReservationState.value), [activeReservationState.value]);

  const columns: GridColDef<Reservation>[] = [
    {
      field: 'reservation_id',
      headerName: 'Reservation ID',
      editable: false,
      width: 100,
    },
    {
      field: 'external_id',
      headerName: 'External ID',
      editable: false,
      width: 160,
    },
    {
      field: 'sid',
      headerName: 'Service ID',
      editable: false,
      width: 240,
    },
    {
      field: 'address',
      headerName: 'Address',
      editable: false,
      width: 240,
    },
    {
      field: 'expires_at',
      headerName: 'Expires In',
      editable: false,
      width: 240,
      renderCell: (params) => (
        // 2024-04-28T22:30:57.936464+10:00
        <Countdown endTime={dayjs(params.value)} callBack={() => dispatch(removeReservation(params.value))} />
      ),
    },
  ];

  const getSelectedRowsToExport = ({
    apiRef,
  }: any): GridRowId[] => {
    const selectedRowIds = selectedGridRowsSelector(apiRef);
    if (selectedRowIds.size > 0) {
      return Array.from(selectedRowIds.keys());
    }

    return gridFilteredSortedRowIdsSelector(apiRef);
  };

  return (
    <Box>
      <Grid container>
        <Grid item sm={12}>
          <Box sx={{ paddingTop: '5%', width: '85%', margin: 'auto' }}>
            <Toolbar />
            <DataGrid
              getRowId={(row) => row.reservation_id}
              autoHeight
              loading={activeReservationState.status === 'loading'}
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'speed', sort: 'asc' }],
                },
              }}
              pageSizeOptions={[100]}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={10} />
      </Grid>
    </Box>
  );
};

export default ActiveReservations;
