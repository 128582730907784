import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import type { Address } from '@pineapple/address-search';
import { addressFromPlace } from '@pineapple/address-search';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { useAppSelector } from '../../app/hooks';
import { Building } from '../../features/buildings/buildings';
import { Campaign, selectCampaigns } from '../../features/campaigns/campaigns';

type AlertState = 'closed' | 'buildingExists' | 'buildingNotFound' | 'buildingExistsOnOtherCampaign' | 'buildingTypeCommercial';

const GOOGLE_MAPS_API_KEY = 'AIzaSyAr1MXyX-CvI1yR4_aruSqbDMbunvnwUAU';
const collator = new Intl.Collator(undefined, { numeric: true });

const ErrorDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  message: string;
}> = ({ open, onClose, message }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <Button onClick={onClose}>OK</Button>
  </Dialog>
);

export const BuildingManager: React.FC<{
  campaign: Campaign;
  onBuildingsChange: (buildings: Building[]) => void;
}> = ({ campaign, onBuildingsChange }) => {
  const campaigns = useAppSelector(selectCampaigns);
  const otherCampaigns = campaigns.filter((c) => c.id !== campaign.id);
  const [
    overlappingCampaignsContainingBuilding,
    setOverlappingCampaignsContainingBuilding,
  ] = useState<Campaign[]>([]);
  const [thisCampaignsBuildingIds, setThisCampaignsBuildingIds] = useState(
    new Set(campaign.buildings.map((b) => b.id)),
  );
  const [openAlert, setOpenAlert] = useState<AlertState>('closed');
  const [buildingCode, setBuildingCode] = useState<string | null>(null);
  const [buildingType, setBuildingType] = useState<string | null>(null);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({ apiKey: GOOGLE_MAPS_API_KEY });
  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [placeDetails, setPlaceDetails] = useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (value) {
      placesService?.getDetails(
        {
          placeId: value.place_id,
          fields: ['place_id',
            'formatted_address', 'address_components'],
        },
        (result) => setPlaceDetails(result),
      );
    }
  }, [value]);

  useEffect(() => {
    if (!placeDetails
    || !placeDetails.place_id
    || !placeDetails.address_components
    || !placeDetails.formatted_address) {
      return;
    }

    addressFromPlace({
      place_id: placeDetails.place_id,
      formatted_address: placeDetails.formatted_address,
      address_components: placeDetails.address_components,
    }).then((address: Address) => {
      setBuildingCode(address.uniqueCode || '');
      setBuildingType(address.commercial ? 'commercial' : 'residential');
    }).catch((err) => { console.log(err); });
  }, [placeDetails]);

  const handleAlertClose = () => {
    setOpenAlert('closed');
    setBuildingCode(null);
    setBuildingType(null);
    setOverlappingCampaignsContainingBuilding([]);
  };

  useEffect(() => {
    if (buildingCode === null || value === null) {
      return;
    }

    if (buildingType?.toLowerCase() === 'commercial') {
      setOpenAlert('buildingTypeCommercial');
      return;
    }

    if (buildingCode === '') {
      setOpenAlert('buildingNotFound');
      return;
    }

    if (thisCampaignsBuildingIds.has(buildingCode)) {
      setOpenAlert('buildingExists');
      return;
    }

    const newOverlappingCampaignsContainingBuilding = otherCampaigns
      .filter((c) => (
        dayjs(c.startDate).isBefore(dayjs(campaign.endDate))
            && dayjs(c.endDate).isAfter(dayjs(campaign.startDate))
      ))
      .filter((c) => c.buildings.some((b) => b.id === buildingCode));

    setOverlappingCampaignsContainingBuilding(newOverlappingCampaignsContainingBuilding);

    if (newOverlappingCampaignsContainingBuilding.length > 0) {
      setOpenAlert('buildingExistsOnOtherCampaign');
      return;
    }

    setThisCampaignsBuildingIds(thisCampaignsBuildingIds.add(buildingCode));
    onBuildingsChange([...thisCampaignsBuildingIds].map((id) => ({ id })));

    setValue(null);
    setBuildingCode(null);
    setBuildingType(null);
  }, [buildingCode, value]);

  const removeBuilding = (id: string) => {
    thisCampaignsBuildingIds.delete(id);
    setThisCampaignsBuildingIds(thisCampaignsBuildingIds);
    onBuildingsChange([...thisCampaignsBuildingIds].map((i) => ({ id: i })));
  };

  return (
    <>
      <Autocomplete
        id="address"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={placePredictions}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        isOptionEqualToValue={(o1, o2) => o1.place_id === o2.place_id}
        onChange={(_, newValue) => setValue(newValue)}
        onInputChange={(_, input) => getPlacePredictions({
          input,
          componentRestrictions: { country: 'au' },
          types: [
            'address',
          ],
        })}
        renderInput={(params) => (<TextField {...params} label="Add an address" fullWidth />)}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part: any) => (
                    <Box
                      key={part.text}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Box mt={2} mb={3}>
        <Typography
          sx={{
            padding: '0 0.5em',
            margin: '0 0.5em',
            position: 'relative',
            top: '0.9em',
            color: 'rgba(0,0,0,0.6)',
            backgroundColor: 'white',
          }}
          variant="caption"
        >
          Buildings
        </Typography>
        <Paper
          variant="outlined"
          sx={{
            padding: '1em 0.5em',
            maxHeight: '5em',
            minHeight: '3em',
            overflow: 'scroll',
          }}
        >
          {[...thisCampaignsBuildingIds].sort((b1, b2) => collator.compare(b1, b2)).map((building) => (
            <Chip
              sx={{ margin: '0.25em' }}
              key={building}
              label={building}
              onDelete={() => removeBuilding(building)}
              deleteIcon={<CloseIcon />}
            />
          ))}
        </Paper>
      </Box>
      <ErrorDialog
        open={openAlert === 'buildingTypeCommercial'}
        onClose={handleAlertClose}
        message="This is a commercial building. Commercial buildings may not be added to campaigns."
      />
      <ErrorDialog
        open={openAlert === 'buildingExists'}
        onClose={handleAlertClose}
        message="This building has already been added to this campaign"
      />
      <ErrorDialog
        open={openAlert === 'buildingExistsOnOtherCampaign'}
        onClose={handleAlertClose}
        message={
          `This building has been added to the campaign ${(overlappingCampaignsContainingBuilding[0] || {})?.name} that overlaps with this one`
        }
      />
      <ErrorDialog
        open={openAlert === 'buildingNotFound'}
        onClose={handleAlertClose}
        message="This building could not be found in the DGtek database"
      />
    </>
  );
};
