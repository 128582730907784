import axios from 'axios';

export const Client = axios.create();

// Intercept 401 and redirect to login page
Client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401
      && !window.location.href.endsWith('/login')
    ) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);
