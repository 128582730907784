import React from 'react';
import { Navigate } from 'react-router-dom';

export type ProtectedRouteProps = {
  auth: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

const ProtectedRoute = ({
  auth,
  authenticationPath,
  outlet,
}: ProtectedRouteProps) => {
  if (auth) {
    return outlet;
  }

  return <Navigate to={{ pathname: authenticationPath }} />;
};

export default ProtectedRoute;
